<template>
  <div style="display:flex;justify-content:space-between;margin-left: 1rem;width: calc(100% - 2rem)">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <Button style="padding: 0px;
    width: 65px;
    border: 1px solid rgb(217, 217, 217);
    margin-bottom: 10px;" label="返回" @click="back()"
                  class="p-button p-component p-col-1 p-button-sm p-button-outlined p-button-secondary"/>
  </div>
  <div class="card">
    <form @submit.prevent="" class="p-fluid" style="margin-left: 20%;margin-right: 20%;">
      <div class="p-field p-grid">
        <label for="id" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目编号:
        </label>
        <div class="p-col">
          <InputText :readonly="true" id="id" v-model.trim="form.code"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="name" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目名称:
        </label>
        <div class="p-col">
          <InputText :readonly="true" id="name" v-model.trim="form.name"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="type" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目类型:
        </label>
        <div class="p-col">
          <Dropdown disabled id="type" optionLabel="name" optionValue="code" v-model="form.type" :options="typeList"
                    placeholder="请选择项目类型"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="type" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          归档类型:
        </label>
        <div class="p-col">
          <InputText :readonly="true" v-model="form.archiveType"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="position" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目坐标:
        </label>
        <div class="p-col">
          <div class="p-inputgroup">
            <InputText :readonly="true" id="position" v-model.trim="form.position"/>
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="location" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目地点:
        </label>
        <div class="p-col">
          <div class="p-grid" style="margin:0;height: 36px">
            <InputText :readonly="true" class="p-col p-mr-2" placeholder="国家" v-model="form.country"
            ></InputText>

            <InputText :readonly="true" class="p-col p-mr-2" id="province" v-model.trim="form.province"/>
            <InputText :readonly="true" class="p-col p-mr-2" id="city" v-model.trim="form.city"/>
            <InputText :readonly="true" class="p-col p-mr-2" id="district" v-model.trim="form.district"/>
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="locationdetail" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>详细地点:
        </label>
        <div class="p-col">
          <InputText v-model.trim="form.location"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="locationdetail" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
        </label>
        <div class="p-col" style="position: relative;">
          <div style="width:auto;height: 250px;flex-grow: 1">
            <show-map ref="showMap" :update-but="false"></show-map>
<!--            <div class="select">-->
<!--              <Button @click="addOrUpdate" style="width: auto">修改</Button>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="aim" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目目标:
        </label>
        <div class="p-col">
          <Textarea :readonly="true" id="aim" v-model.trim="form.aim" :autoResize="true" rows="5" cols="30"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="effect" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          实验效果:
        </label>
        <div class="p-col">
          <Textarea :readonly="true" id="effect" v-model.trim="form.effect" :autoResize="true" rows="5" cols="30"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="yield" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          亩产:
        </label>
        <div class="p-col">
          <InputText :readonly="true" id="yield" v-model.trim="form.yield"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="area" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          种植面积:
        </label>
        <div class="p-col">
          <InputText :readonly="true" id="area" v-model.trim="form.area"/>
        </div>
      </div>


      <div class="p-field p-grid" style="margin-bottom: 0;height: 22px;">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">整地条件:</label>
        <div class="p-col p-d-flex" style="height: 21px;">
          <div v-for="(item,index) in allList" :key="index" :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'m'+index" name="menu" :value="item.name" v-model="form.cond"/>
            <label :for="'m'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="p-field p-grid" style="margin-bottom: 0;margin-top: 26px;">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;">灌溉条件:</label>
        <div class="p-col p-d-flex" style="height: 21px;">
          <div v-for="(item,index) in allLists" :key="index" :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'a'+index" name="menu" :value="item.name" v-model="form.irrigation"/>
            <label :for="'a'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>

        </div>
      </div>
      <div class="p-field p-grid" style="margin-top: 6px;">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;"></label>
        <div class="p-col" style="display:flex;justify-content: space-between">
          <InputNumber style="width: 50%" placeholder="请输入金额" v-model="form.irrigationPrice"></InputNumber>
          <div style="width: calc(50% - 10px);display: inline-block">
            <Dropdown v-model="form.irrigationPriceUnit" :options="citie"
                      optionLabel="name" option-value="name"/>
          </div>
        </div>
      </div>
      <div class="p-field p-grid" style="margin-bottom: 22px">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;">土壤属性:</label>
        <div class="p-col  p-d-flex">
          <p v-for="(item,index) in allLi" :key="index" @click="faklsd(item.id)"
             :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'n'+index" name="menu" :value="item.name" v-model="form.soil"/>
            <label :for="'n'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </p>
        </div>
      </div>
      <div class="p-field p-grid" v-if="askey">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;"></label>
        <div class="p-col">
          <div style="border: 1px solid #cccccc;margin-top: 6px;padding-bottom: 8px;">
            <div class="p-field p-grid" style="margin-bottom: 0">
              <label class="p-col-fixed"
                     style="width:100px;justify-content: flex-end;margin-top: 8px;">土壤属性</label>
              <div class="p-col p-d-flex">
                <Slider v-model="form.soilPh" :min="0" :max="20" style="width: 80px;margin-top: 23px"/>
                <label class="p-col-fixed" style="margin: 13px 5px 0 5px;">pH值</label>
                <InputNumber v-model="form.soilPh" style="width: 100px;margin-top: 8px;"
                ></InputNumber>
              </div>
            </div>

            <div class="p-field p-grid" style="margin-top: 26px;">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">含盐量:</label>
              <div class="p-col">
                <Dropdown style="width: 110px;" v-model="form.soilSalt" :options="yan" optionLabel="name"
                          option-value="name"/>
              </div>
            </div>
            <div class="p-field p-grid" style="margin-bottom: 0;">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">电导率(EC)</label>
              <div class="p-col p-d-flex">
                <InputNumber style="width: 110px;margin-right: 18px;margin-bottom: 4px;"
                             v-model="form.soilConductivity"
                ></InputNumber>
                <Dropdown style="width: 110px;margin-bottom: 4px;" v-model="form.soilConductivityUnit"
                          :options="dao"
                          option-value="name"
                          optionLabel="name"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">用于生态治理:</label>
        <div class="p-col p-d-flex">
          <div v-for="(item,index) in yes" :key="index"
               :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'b'+index" name="menu" :value="item.id" v-model="form.isEcoGovernance"/>
            <label :for="'b'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="p-field p-grid" v-if="stzimb">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">生态治理目标:</label>
        <div class="p-col">
          <p v-for="(item,index) in process" :key="index">
            <Checkbox :id="'c'+index" name="menu" :value="item.name" v-model="checkbox"/>
            <label :for="'c'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </p>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">菌草成熟后再利用:</label>
        <div class="p-col p-d-flex">
          <div v-for="(item,index) in yes" :key="index" :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'d'+index" name="menu" :value="item.id" v-model="form.isReuse"/>
            <label :for="'d'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">菌草成熟收获后的加工目标:</label>
        <div class="p-col">
          <p v-for="(item,index) in target" :key="index">
            <Checkbox :id="'e'+index" name="menu" :value="item.name" v-model="checkboxs"/>
            <label :for="'e'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </p>
        </div>
      </div>



      <div class="p-field p-grid">
        <label for="endTime" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          项目周期:
        </label>
        <div class="p-col">
          <Calendar :readonly="true" id="endTime" :numberOfMonths="2" :manualInput="false" adonly dateFormat="yy/mm/dd"
                    selectionMode="range" v-model="form.endDate"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="comments" class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end">
          备注:
        </label>
        <div class="p-col">
          <Textarea :readonly="true" id="comments" v-model.trim="form.comments" :autoResize="true" rows="5" cols="30"/>
        </div>
      </div>
    </form>
  </div>

</template>

<script>

import ShowMap from "@/views/modules/plan/showMap";
import enclosure from "@/views/modules/plan/enclosure";

export default {
  data() {
    return {
      form: {
        code: null,
        name: null,
        type: null,
        archiveType: null,
        position: null, // 仅供显示，数据库不保存此字段
        positionLon: null,
        positionLat: null,
        location: null,
        aim: null,
        effect: null,
        yield: null,
        area: null,
        startDate: null,
        endDate: null,
        comments: null,
        $submitted: false,
        id: null,
        country: null,
        areaUnit: null,
        cond: null,
        areaMap: null,
        irrigation: null,
        irrigationPrice: null,
        irrigationPriceUnit: null,
        soil: null,
        soilPh: null,
        soilSalt: null,
        soilConductivity: null,
        soilConductivityUnit: null,
        isEcoGovernance: null,
        ecoGovernanceAim: null,
        isReuse: null,
        procAim: null,
      },
      selectedPosition: null,
      typeList: [
        {name: '科研/防风固沙', code: '科研/防风固沙'},
        {name: '科研/根系越冬', code: '科研/根系越冬'},
        {name: '科研/环境监测', code: '科研/环境监测'},
        {name: '生产/育种', code: '生产/育种'},
        {name: '生产/生产田间管理', code: '生产/生产田间管理'},
        {name: '生产/猪饲料-发酵床', code: '生产/猪饲料-发酵床'},
        {name: '生产/种菇', code: '生产/种菇'}
      ],
      breadcrumb: {
        home: {label: '种植项目', to: '#'},
        items: [
          {label: '项目管理', to: '#'},
          {label: '归档的项目', to: '#'}
        ],
      },
      dialog: {
        show: false
      },


      askey: true,
      checkbox: [],
      checkboxs: [],
      stzimb: false,
      process: [
        {id: 0, name: '水土流失'},
        {id: 1, name: '荒漠化'},
        {id: 2, name: '防风固沙'},
        {id: 3, name: '石漠化'},
        {id: 4, name: '盐碱地'},
        {id: 5, name: '矿山修复'},
        {id: 6, name: '土壤修复'},
        {id: 7, name: '护坡固堤'},
      ],
      target: [
        {id: 0, name: '菌料'},
        {id: 1, name: '饲料'},
        {id: 2, name: '生物燃料'},
        {id: 3, name: '新型板材'},
        {id: 4, name: '纤维材料'},
      ],
      items: [
        {label: '规划地块信息',},
        {label: '种植菌草用途'},
        {label: '其他信息'}

      ],
      yes: [
        {id: 0, name: '是'},
        {id: 1, name: '否'},
      ],

      bools: false,
      bool: true,
      selectedCity: {name: '亩'},
      selec: {name: '元/亩'},
      daindao: {name: 'ms/cm'},
      hanyan: {name: '<0.3%'},
      cities: [
        {name: '亩'},
      ],
      citie: [
        {name: '元/亩'},
      ],
      dao: [
        {name: 'ms/cm'},
        {name: 'μs/cm'}
      ],
      yan: [
        {name: '<0.3%'},
        {name: '0.3~0.6%'},
        {name: '>0.6%'},
      ],
      allList: [
        {id: 0, name: '平地'},
        {id: 1, name: '小于25度坡地'},
        {id: 2, name: '梯田'},
      ],
      allLists: [
        {id: 0, name: '喷灌'},
        {id: 1, name: '滴灌'},
        {id: 2, name: '浸灌'},
        {id: 3, name: '自然降雨'},
      ],
      allLi: [
        {id: 0, name: '红壤'},
        {id: 1, name: '沙壤地'},
        {id: 2, name: '湿地(草甸/沼泽地)'},
        {id: 3, name: '黑土'},
        {id: 4, name: '盐碱地'},
      ],
      addOrUpdateDisplay: false,
    }
  },
  mounted() {

    let me = this;
    let query = this.$route.query;
    if (query.action == "view" || query.action == "edit") {
      me.$http('/project/get', 'get', {id: query.id}, function (response) {
        me.form = response.data;
        me.form.position = me.form.positionLon + "," + me.form.positionLat;
        me.selectedPosition = {
          lon: me.form.positionLon,
          lat: me.form.positionLat
        }
        me.form.endDate = new Date(me.form.startDate).toLocaleDateString() + "-" + new Date(me.form.endDate).toLocaleDateString();
        if (me.form.archiveType) {
          if (me.form.archiveType == "year") {
            me.form.archiveType = "按年";
          } else if (me.form.archiveType == "season") {
            me.form.archiveType = "按季度";
          } else if (me.form.archiveType == "other") {
            me.form.archiveType = "其他";
          }
        }
        if (me.form.isEcoGovernance === 0) {
          me.stzimb = true
        } else {
          me.stzimb = false
        }
        if (me.form.ecoGovernanceAim && me.form.ecoGovernanceAim.indexOf(',') != -1) {
          me.checkbox = me.form.ecoGovernanceAim.split(',')
        }
        console.log(me.form.procAim)
        if (me.form.procAim && me.form.procAim.indexOf(',') != -1) {
          me.checkboxs = me.form.procAim.split(',')
        }
        me.form.position = me.form.positionLon + "," + me.form.positionLat;
        me.selectedPosition = {
          lon: me.form.positionLon,
          lat: me.form.positionLat
        }

        let info = {
          area: response.data.area,
          path: JSON.parse(response.data.areaMap)
        }
        me.$nextTick(() => {
          me.$refs.showMap.update(info)
        })
      })
    }
  },
  components: {ShowMap, enclosure},
  methods: {
    back() {
      this.$router.go('-1');
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
